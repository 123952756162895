import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import {
    Box,
    createIcon,
    Flex,
    Heading,
    SimpleGrid,
    Text,
    HStack,
    StackProps,
    HTMLChakraProps,
    useColorModeValue as mode,
    Button
} from '@chakra-ui/react'

import { CheckIcon, ArrowForwardIcon } from '@chakra-ui/icons'

import { openPopupWidget } from "react-calendly";

export const RightArrow = createIcon({
    viewBox: '0 0 11 12',
    d: 'M0 0L4.8 6L0 12H5.78182L10.5818 6L5.78182 0H0Z',
})

const FeatureLink = (props: HTMLChakraProps<'a'>) => {
    const { children, ...rest } = props as StackProps
    return (
        <HStack as="a" align="center" fontSize="md" className="group" cursor="pointer" {...rest}>
            <CheckIcon w={4} h={4} color="green.500" />
            <Box fontWeight="semibold">{children}</Box>
            {/* <RightArrow
                color={mode('blue.500', 'blue.400')}
                fontSize="sm"
                transition="transform 0.2s"
                pos="relative"
                top="2px"
                _groupHover={{ transform: 'translateX(2px)' }}
            /> */}
        </HStack>
    )
}

const Services2 = () => {
    const url = 'https://calendly.com/daniosdigital/videollamada-consultoria-gratis'
    //, prefill, pageSettings, utm
    const onClick = () => openPopupWidget({ url });

    return (
        <>
            <Box id="webtech" as="section" py="12" >
                <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
                    <Flex direction={{ base: 'column-reverse', lg: 'row' }} justify="space-between">

                        <Box ms={{ lg: '12' }} mt={{ base: '0', lg: '0' }} flex="1" maxW={{ lg: 'xl' }}>
                            <Box maxW={{ base: 'xl', lg: 'unset' }}>
                                <Text px="1" fontWeight="extrabold" letterSpacing="tight" fontSize="xl" color={mode('blue.500', 'blue.300')}>
                                    SITIO Y TECNOLOGÍA WEB
                                </Text>

                                <Heading mt="0" mb="5" size="2xl" fontWeight="extrabold" lineHeight="1.2">
                                    Website para captar nuevos pacientes
                                    {/* Optimized Dental Website Increases New Patient Appointments by 200%! */}
                                </Heading>
                                <Text fontSize="lg" color={mode('gray.600', 'gray.400')}>
                                    Una experiencia de usuario cuidadosamente diseñada y un sitio web optimizado con
                                    modernas tecnologías son la clave para convertir visitantes en nuevos pacientes.
                                    Cada elemento dispuesto según la estrategia de marketing permite incrementar la efectividad
                                    al integrar capacidades de agendar citas en línea y chatbots.
                                </Text>
                            </Box>
                            <SimpleGrid columns={{ base: 1, md: 2 }} mt="8">
                                {links.map((link) => (
                                    <Flex key={link.label} align="center" minH="14" borderBottomWidth="1px">
                                        <FeatureLink href={link.href}>{link.label}</FeatureLink>
                                    </Flex>
                                ))}
                            </SimpleGrid>
                            <HStack mt={6} justify={'center'}>
                                <Button onClick={onClick} rightIcon={<ArrowForwardIcon />} colorScheme="blue" variant="outline">
                                    Más Información
                                </Button>
                            </HStack>
                        </Box>
                        <Box flex="1" maxW={{ lg: 'xl' }}>
                            <StaticImage style={{ width: '100%', objectFit: 'cover' }} src="../../assets/website.svg" alt="Marketing Dental" />
                            {/* <Img
                                htmlWidth="560px"
                                htmlHeight="430px"
                                w="100%"
                                h="100%"
                                objectFit="cover"
                                src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2550&q=80"
                            /> */}
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </>
    )
}

const links = [
    { label: 'Diseño Web Dental', href: '#' },
    { label: 'Calendario de Citas Online', href: '#' },
    { label: 'Chatbots Dental', href: '#' },
]

export default Services2