import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import {
    Box,
    createIcon,
    Flex,
    Heading,
    Img,
    SimpleGrid,
    Text,
    HStack,
    StackProps,
    HTMLChakraProps,
    useColorModeValue as mode,
    Button
} from '@chakra-ui/react'

import { CheckIcon, ArrowForwardIcon } from '@chakra-ui/icons'

import { openPopupWidget } from "react-calendly";

export const RightArrow = createIcon({
    viewBox: '0 0 11 12',
    d: 'M0 0L4.8 6L0 12H5.78182L10.5818 6L5.78182 0H0Z',
})

const FeatureLink = (props: HTMLChakraProps<'a'>) => {
    const { children, ...rest } = props as StackProps
    return (
        <HStack as="a" align="center" fontSize="md" className="group" cursor="pointer" {...rest}>
            <CheckIcon w={4} h={4} color="green.500" />
            <Box fontWeight="semibold">{children}</Box>
            {/* <RightArrow
                color={mode('blue.500', 'blue.400')}
                fontSize="sm"
                transition="transform 0.2s"
                pos="relative"
                top="2px"
                _groupHover={{ transform: 'translateX(2px)' }}
            /> */}
        </HStack>
    )
}

const Services1 = () => {
    const url = 'https://calendly.com/daniosdigital/videollamada-consultoria-gratis'
    //, prefill, pageSettings, utm
    const onClick = () => openPopupWidget({ url });

    return (
        <>
            <Box /* bgColor="gradient.yellow.end" */ as="section" id="marketingdental">
                <Box
                    pt={{ base: 0, sm: 0 }}
                    position="relative"
                    maxW="7xl"
                    mx="auto"
                    px={{ base: '4', sm: '6' }}
                    /* py={{ base: '8', lg: '16' }} */
                    py={{ base: '0', lg: '16' }}
                >
                    <Box maxW={{ base: '2xl', lg: 'unset' }} mx="auto" pl={{ lg: '10' }} >
                        <Box textAlign="center" maxW="2xl" mx="auto">
                            <Heading textTransform={'uppercase'} mt="6" fontSize={{ base: '3xl', sm: '5xl' }} fontWeight="extrabold">
                                Soluciones para Negocios Dentales
                            </Heading>
                            <Text

                                fontWeight="medium"
                                mt="4"
                                fontSize="lg"
                            >
                                Nuestros servicios de Marketing Dental te ayudarán a descubrir el potencial de crecimiento de tu clínica y alcanzar los objetivos de negocio.</Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* pt={{ base: '0', lg: '0' }} pb={{ base: '0' }} */}
            {/* py="24" */}
            <Box as="section" pb="12" >
                <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
                    <Flex direction={{ base: 'column', lg: 'row' }} justify="space-between">
                        <Box flex="1" maxW={{ lg: 'xl' }}>
                            {/* <StaticImage style={{ width: '100%', height: '100%', objectFit: 'cover' }} src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2550&q=80" alt="Doctor celebrando" /> */}
                            <StaticImage style={{ width: '100%', height: '100%', objectFit: 'cover' }} src="../../assets/marketing.svg" alt="Marketing Dental" />



                            {/* <StaticImage src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2550&q=80" alt="A dinosaur" /> */}
                            {/* <Img
                                    htmlWidth="560px"
                                    htmlHeight="430px"
                                    w="100%"
                                    h="100%"
                                    objectFit="cover"
                                    src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2550&q=80"
                                /> */}


                        </Box>
                        <Box ms={{ lg: '12' }} mt={{ base: '12', lg: 0 }} flex="1" maxW={{ lg: 'xl' }}>
                            <Box maxW={{ base: 'xl', lg: 'unset' }}>
                                <Text px="1" fontWeight="extrabold" letterSpacing="tight" fontSize="xl" color={mode('blue.500', 'blue.300')}>MARKETING DENTAL DIGITAL</Text>
                                {/* <Box as="b" color={mode('blue.500', 'blue.300')}>
                                    Marketing Dental Digital
                                </Box> */}
                                <Heading mt="0" mb="5" size="2xl" fontWeight="extrabold" lineHeight="1.2">
                                    Flujo de pacientes de forma inteligente
                                </Heading>
                                <Text fontSize="lg" color={mode('gray.600', 'gray.400')}>
                                    Con base en los objetivos de negocio, desarrollamos un marketing digital estrategico
                                    y a la medida. Esto nos permite desplegar herramientas y tácticas para disparar el
                                    ritmo de primeras visitas y mejorar la experiencia online de tu marca dental.
                                </Text>
                            </Box>
                            <SimpleGrid columns={{ base: 1, md: 2 }} mt="8">
                                {links.map((link) => (
                                    <Flex key={link.label} align="center" minH="14" borderBottomWidth="1px">
                                        <FeatureLink href={link.href}>{link.label}</FeatureLink>
                                    </Flex>
                                ))}
                            </SimpleGrid>
                            <HStack mt={6} justify={'center'}>
                                <Button onClick={onClick} rightIcon={<ArrowForwardIcon />} colorScheme="blue" variant="outline">
                                    Más Información
                                </Button>
                            </HStack>
                        </Box>
                    </Flex>
                </Box>
            </Box>

        </>
    )
}

const links = [
    { label: 'Estrategia Dental Digital', href: '#' },
    { label: 'SEO Dental', href: '#' },
    { label: 'Publicidad Dental Online', href: '#' },
    { label: 'Gestión de Redes Sociales', href: '#' },
    { label: 'Email Marketing Dental', href: '#' },
    { label: 'Marketing de Contenido Dental', href: '#' },
]

export default Services1