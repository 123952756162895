import * as React from "react"

import Layout from "../components/layouts/layout"
import Seo from "../components/utils/seo"
import HomeHero from "../components/sections/homeHero"
import Services1 from "../components/sections/services1"
import Services2 from "../components/sections/services2"
import Services3 from "../components/sections/services3"
import Cta from "../components/sections/cta"




const IndexPage = () => (

    <Layout>
        <Seo title="Inicio" />
        <HomeHero />
        <Services1 />
        <Services2 />
        <Services3 />
        <Cta />
    </Layout>

)

export default IndexPage