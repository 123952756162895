import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import {
    Box,
    createIcon,
    Flex,
    Heading,
    SimpleGrid,
    Text,
    HStack,
    StackProps,
    HTMLChakraProps,
    useColorModeValue as mode,
    Button
} from '@chakra-ui/react'

import { CheckIcon, ArrowForwardIcon } from '@chakra-ui/icons'

import { openPopupWidget } from "react-calendly";

export const RightArrow = createIcon({
    viewBox: '0 0 11 12',
    d: 'M0 0L4.8 6L0 12H5.78182L10.5818 6L5.78182 0H0Z',
})

const FeatureLink = (props: HTMLChakraProps<'a'>) => {
    const { children, ...rest } = props as StackProps
    return (
        <HStack as="a" align="center" fontSize="md" className="group" cursor="pointer" {...rest}>
            <CheckIcon w={4} h={4} color="green.500" />
            <Box fontWeight="semibold">{children}</Box>
            {/* <RightArrow
                color={mode('blue.500', 'blue.400')}
                fontSize="sm"
                transition="transform 0.2s"
                pos="relative"
                top="2px"
                _groupHover={{ transform: 'translateX(2px)' }}
            /> */}
        </HStack>
    )
}

const Services2 = () => {
    const url = 'https://calendly.com/daniosdigital/videollamada-consultoria-gratis'
    //, prefill, pageSettings, utm
    const onClick = () => openPopupWidget({ url });

    return (
        <>
            <Box id="reputaciononline" as="section" py="12">
                <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
                    <Flex direction={{ base: 'column', lg: 'row' }} justify="space-between">
                        <Box flex="1" maxW={{ lg: 'xl' }}>
                            <StaticImage style={{ width: '100%', objectFit: 'cover' }} src="../../assets/reputation.svg" alt="Reputación en Línea" />
                            {/* <Img
                                htmlWidth="560px"
                                htmlHeight="430px"
                                w="100%"
                                h="100%"
                                objectFit="cover"
                                src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2550&q=80"
                            /> */}
                        </Box>
                        <Box ms={{ lg: '12' }} mt={{ base: '12', lg: 0 }} flex="1" maxW={{ lg: 'xl' }}>
                            <Box maxW={{ base: 'xl', lg: 'unset' }}>
                                <Text px="1" fontWeight="extrabold" letterSpacing="tight" fontSize="xl" color={mode('blue.500', 'blue.300')}>
                                    REPUTACIÓN ONLINE DENTAL
                                    {/* DENTAL REPUTATION MANAGEMENT */}
                                </Text>
                                {/* <Box as="b" color={mode('blue.500', 'blue.300')}>
                                    Marketing Dental Digital
                                </Box> */}
                                <Heading mt="0" mb="5" size="2xl" fontWeight="extrabold" lineHeight="1.2">
                                    Atrae más pacientes al gestionar tu reputación
                                    {/* Attract 300% More Patients by Managing Your Online Dental Reputation. */}
                                </Heading>
                                <Text fontSize="lg" color={mode('gray.600', 'gray.400')}>
                                    Una reputación online positiva te ayudará a generar mayor confianza e incrementar las
                                    primeras visitas. Podemos ayudarte a gestionar tu reputación dental online y crear esa
                                    primera buena impresión donde sea que los potenciales pacientes estén buscando información:
                                    Google, directorios dentales, redes sociales, reseñas, etc.

                                    {/* Building and maintaining a positive online dental reputation will deliver an exponential
                                    increase in new patient appointments. Our dental reputation management team can help you
                                    to create an outstanding first impression on Google, review sites like Yelp, and social
                                    media platforms, where your new patients are searching for a reliable dentist to choose. */}
                                </Text>
                            </Box>
                            <SimpleGrid columns={{ base: 1, md: 2 }} mt="8">
                                {links.map((link) => (
                                    <Flex key={link.label} align="center" minH="14" borderBottomWidth="1px">
                                        <FeatureLink href={link.href}>{link.label}</FeatureLink>
                                    </Flex>
                                ))}
                            </SimpleGrid>
                            <HStack mt={6} justify={'center'}>
                                <Button onClick={onClick} rightIcon={<ArrowForwardIcon />} colorScheme="blue" variant="outline">
                                    Más Información
                                </Button>
                            </HStack>

                        </Box>

                    </Flex>
                </Box>
            </Box>
        </>
    )
}

const links = [
    { label: 'Gestión de Reseñas Dentales', href: '#' },
    { label: 'Encuestas de Satisfacción de Pacientes', href: '#' },
    { label: 'Gestión de Directorios Dentales', href: '#' },
]

export default Services2