import React from "react";
import { StaticImage } from "gatsby-plugin-image"

import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { openPopupWidget } from "react-calendly";

import { ArrowForwardIcon } from '@chakra-ui/icons'

export const HomeHero = () => {
  const url = 'https://calendly.com/daniosdigital/videollamada-consultoria-gratis'
  //, prefill, pageSettings, utm
  const onClick = () => openPopupWidget({ url });


  return (
    <Box
      as="section"
      /* bg={mode('gray.50', 'gray.800')} */
      /* pb="24" */
      pb={{ base: '12', lg: '24' }}
      pos="relative"
      px={{ base: '6', lg: '12' }}

    >
      <Box maxW="7xl" mx="auto">
        <Box
          maxW={{ lg: 'md', xl: 'xl' }}
          pt={{ base: '20', lg: '40' }}
          pb={{ base: '16', lg: '24' }}
        >


          <Text px="1" fontWeight="extrabold" letterSpacing="tight" fontSize="xl">MARKETING DENTAL</Text>


          <Heading as="h1" size="3xl" lineHeight="1" fontWeight="extrabold" letterSpacing="tight">
            <Text
              display={{ base: "block", lg: "inline" }}
              w="full"
              bgClip="text"
              bgGradient="linear(to-r, green.400,purple.500)"
              fontWeight="extrabold"
            >
              Más pacientes,{' '}
            </Text>
            <Text >
              más sonrisas.

            </Text>


            {/* <Box as="mark" color={mode('blue.500', 'blue.300')} bg="transparent">
              más sonrisas.
            </Box> */}
          </Heading>
          <Text mt={4} fontSize="xl" fontWeight="medium" color={mode('gray.600', 'gray.400')}>
            Ayudamos a clínicas dentales a convertir desconocidos en pacientes leales ahorrando tiempo y dinero.
          </Text>
          <Stack direction={{ base: 'column', sm: 'row' }} spacing="4" mt="8">
            <Button onClick={onClick} size="lg" href="#" rightIcon={<ArrowForwardIcon />} colorScheme="blue" height="14" px="8" fontSize="md" fontWeight="bold">
              Agendar Video LLamada
              {/* <Icon boxSize={7} ml={1} viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                  clipRule="evenodd"
                />
              </Icon> */}
            </Button>

          </Stack>
        </Box>
      </Box>
      <Box
        pos={{ lg: 'absolute' }}
        insetY={{ lg: '0' }}
        insetEnd={{ lg: '0' }}
        bg="gray.50"
        w={{ base: 'full', lg: '50%' }}
        height={{ base: '96', lg: 'full' }}
        sx={{
          clipPath: { lg: 'polygon(8% 0%, 100% 0%, 100% 100%, 0% 100%)' },
        }}
      >
        {/* <HeroImg /> */}
        <StaticImage style={{ width: '100%', height: '100%', objectFit: 'cover' }} src="../../images/thumbup.png" alt="Doctor" />
        {/* <Img
          height="100%"
          width="100%"
          objectFit="cover"
          src="https://images.unsplash.com/photo-1551836022-b06985bceb24?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80"
          src={`thumbup2.png`}
          alt="Lady working"
        /> */}
      </Box>
    </Box>
  )
}
export default HomeHero